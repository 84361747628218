import {TimelineMax, TweenMax, Power4, Power2} from 'gsap';
// @ts-ignore
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';

export class ContentRevealAnimation {
    private readonly items = document.querySelectorAll<HTMLElement>('.js-reveal-item');
    private scenes: any = [];

    // images reveal
    private readonly images = document.querySelectorAll<HTMLElement>('.js-reveal-image-wrapper');
    private imageScenes: any = [];

    constructor() {
        let controller = new ScrollMagic.Controller();
        if (this.items.length > 0) {
            this.init(controller);
        }

        if (this.images.length > 0) {
            this.imageReveal(controller);
        }
    }

    private init(controller: any): void {
        let offset: number = window.innerHeight / 4;

        for (let i = 0; i < this.items.length; i++) {

            // hardcoadedOffset is value from 0 to 1
            // @ts-ignore
            let hardcodedOffset: number = parseFloat(this.items[i].dataset.animationOffset) + 1 || 1;
            // set initial state
            TweenMax.set(this.items[i], {
                y: 100,
                autoAlpha: 0
            });

            let tween = TweenMax.to(this.items[i], 1, {
                y: 0,
                autoAlpha: 1,
                ease: Power4.easeOut
            });

            this.scenes[i] = new ScrollMagic.Scene({
                triggerElement: this.items[i],
                triggerHook: 1,
                offset: offset * hardcodedOffset
            })
                .setTween(tween)
                .addTo(controller);
        }
    }

    private imageReveal(controller: any): void {
        // let offset: number = window.innerHeight / 2;

        for (let i = 0; i < this.images.length; i++) {
            let offset: number = this.images[i].offsetHeight / 4;

            // hardcoadedOffset is value from 0 to 1
            // @ts-ignore
            let hardcodedOffset: number = parseFloat(this.images[i].dataset.animationOffset) + 1 || 1;

            let lines: NodeListOf<Element> = this.images[i].querySelectorAll<HTMLElement>('.js-reveal-line');

            const img: HTMLElement = <HTMLElement>this.images[i].querySelector('.js-reveal-image');

            // set initial state
            TweenMax.set(img, {
                autoAlpha: 0,
                scale: 1.1
            });

            TweenMax.set(lines, {
                x: '-101%'
            });

            controller = new ScrollMagic.Controller();

            let tween = new TimelineMax()
                .staggerTo(lines, 0.45, {
                    x: '0%',
                    ease: Power4.easeIn,
                }, 0.06)
                .add('lines')
                .add('image', '-=0.4')
                .to(img, 0.7, {
                    autoAlpha: 1,
                    scale: 1,
                    delay: 0.35,
                    ease: Power2.easeOut
                }, 'image')
                .staggerTo(lines, 0.55, {
                    x: '101%',
                    ease: Power4.easeOut,
                }, 0.07, 'lines');

            this.imageScenes[i] = new ScrollMagic.Scene({
                triggerElement: this.images[i],
                triggerHook: 1,
                offset: offset * hardcodedOffset
            })
                .setTween(tween)
                .reverse(false)
                .addTo(controller);
        }
    }
}
