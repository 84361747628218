import {TweenMax, Power3} from 'gsap';


export class HeaderElementsReveal {
    private readonly items = document.querySelectorAll<HTMLElement>('.js-header-reveal-item');
    private readonly stamp: HTMLElement = <HTMLElement>document.querySelector('.js-header-stamp');
    private readonly menuButton: HTMLElement = <HTMLElement>document.querySelector('.js-hamburger-button');
    private readonly menuContactButton: HTMLElement = <HTMLElement>document.querySelector('.js-contact-button');

    constructor() {
        if (this.items.length > 0) {
            setTimeout(() => {
                this.init();
            }, 300);
        }
    }

    private init(): void {
        TweenMax.to(this.stamp, 1, {
            autoAlpha: 1,
            delay: 0.4,
            ease: Power3.easeOut
        });

        TweenMax.to(this.menuButton, 1, {
            autoAlpha: 1,
            delay: 0.4,
            ease: Power3.easeOut
        });

        TweenMax.set(this.menuContactButton, {
            y: 35,
            autoAlpha: 0
        });

        TweenMax.to(this.menuContactButton, 1, {
            y: 0,
            autoAlpha: 1,
            delay: 0.4,
            ease: Power3.easeOut
        });

        for (let i = 0; i < this.items.length; i++) {
            TweenMax.set(this.items[i], {
                y: 70,
                autoAlpha: 0
            });

            // @ts-ignore
            let offset: number = this.items[i].dataset.animationTimeOffset || 0;

            TweenMax.to(this.items[i], 1, {
                y: 0,
                autoAlpha: 1,
                delay: offset,
                ease: Power3.easeOut
            });
        }
    }
}
