import {TweenMax, Power0} from 'gsap';
// @ts-ignore
import ScrollMagic from 'scrollmagic';
import Scrollbar from 'smooth-scrollbar';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
// @ts-ignore
import is from 'is_js';

export class HorizontalParallax {
    private readonly containers = document.querySelectorAll<HTMLElement>('.js-heading-scroll-trigger');
    private scenes: any = [];
    private readonly _scroll: Scrollbar;

    get scroll(): Scrollbar {
        return this._scroll;
    }

    constructor() {
        if (this.containers.length === 0) {
            return;
        }
        const mainWrapper: HTMLElement = <HTMLElement>document.querySelector('#kuwait-main-wrapper');

        if (is.not.safari()) {
            mainWrapper.style.overflow = 'hidden';

            this._scroll = Scrollbar.init(mainWrapper, {
                damping: 0.08
            });
        }

        this.init();
    }

    private init(): void {
        let controller;
        if (is.not.safari()) {
            controller = new ScrollMagic.Controller({
                refreshInterval: 0
            });
        } else {
            controller = new ScrollMagic.Controller();
        }

        for (let i = 0; i < this.containers.length; i++) {
            this.createScene(i, controller, this.containers[i]);
        }
    }

    private createScene(index: number, controller: any, container: HTMLElement) {
        let y = 0;

        if (is.not.safari()) {
            controller.scrollPos(function () {
                return y;
            });
        }

        const elements: NodeListOf<HTMLElement> = container.querySelectorAll('.js-heading-row');

        for (let i = 0; i < elements.length; i++) {
            const duration = elements[i].offsetHeight + window.innerHeight;
            let direction: string = <string>elements[i].dataset.direction;
            let section: string = <string>elements[i].dataset.section || '';
            let dirIndex: number = 1;
            let offset: number = 0;

            let movementPercentage: number = Math.floor((window.innerWidth / elements[i].offsetWidth) * 100);

            if (window.innerWidth < elements[i].clientWidth) {
                movementPercentage = Math.floor((elements[i].offsetWidth / window.innerWidth) * 100);
            }

            if (direction === 'left') {
                dirIndex = -1;
            }

            let offsetPercentage: string = `${-dirIndex * movementPercentage}%`;

            if (section === 'header') {
                offset = window.innerHeight / 2;
                offsetPercentage = `${-dirIndex * movementPercentage / 10}%`;
            }

            let tween = TweenMax.fromTo(elements[i], 0.1, {
                x: offsetPercentage
            }, {
                x: `${dirIndex * movementPercentage}%`,
                force3D: true,
                ease: Power0.easeNone,
            });

            this.scenes[index] = new ScrollMagic.Scene({
                triggerElement: elements[i],
                duration: duration,
                triggerHook: 1,
                offset: offset
            })
                .setTween(tween)
                .addTo(controller);
        }

        if (is.not.safari()) {
            // @ts-ignore
            this._scroll.addListener((status) => {
                y = status.offset.y;

                controller.update();
            });
        }
    }
}
