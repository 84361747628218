import "style/style.scss";

import {domReady} from "@/utils/domReady";
import {Menu} from "@/components/Menu";
import {ApplicationSwipper} from "@/components/ApplicationSwipper";
import {HorizontalParallax} from "@/components/HorizontalParallax";
import {Noise} from "@/components/Noise";
import {ContentRevealAnimation} from "@/components/ContentRevealAnimation";
import {HeaderElementsReveal} from "@/components/HeaderElementsReveal";
// import {ImageHoverAnimation} from "@/components/ImageHoverAnimation";
import {ItemParallax} from "@/components/ItemParallax";

domReady(() => {
    // tslint:disable-next-line

    new ApplicationSwipper();
    new HeaderElementsReveal();
    new ContentRevealAnimation();
    new Noise();
    const horizontalParallax = new HorizontalParallax();
    new Menu(horizontalParallax);
    new ItemParallax(horizontalParallax);

    // new ImageHoverAnimation();
});
