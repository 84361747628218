import Swiper from 'swiper';
import {Power0, Power4, TimelineMax, TweenMax} from 'gsap';
// @ts-ignore
import is from 'is_js';

export class ApplicationSwipper {
    private readonly sliders = document.querySelectorAll<HTMLElement>('.swiper-container');
    private readonly pointer: HTMLElement = <HTMLElement>document.querySelector('.js-gallery-cursor');
    private readonly cursorArrowLeft: HTMLElement = <HTMLElement>document.querySelector('.js-gallery-cursor-arrow-2');
    private readonly cursorArrowRight: HTMLElement = <HTMLElement>document.querySelector('.js-gallery-cursor-arrow-1');
    private readonly containers: NodeList = document.querySelectorAll<HTMLElement>('.js-kuwait-gallery');
    private readonly circle1: SVGElement = <SVGElement>this.pointer.querySelector('.circle-1');
    private readonly circle1path: SVGElement = <SVGElement>this.circle1.querySelector('path');
    private readonly circle2: SVGElement = <SVGElement>this.pointer.querySelector('.circle-2');
    private readonly circle2path: SVGElement = <SVGElement>this.circle2.querySelector('path');
    private readonly docEl: HTMLElement = <HTMLElement>document.documentElement;
    private readonly images: NodeListOf<HTMLElement> = document.querySelectorAll('.js-kuwait-gallery-image');
    private isAnimating: boolean = false;

    private tl = new TimelineMax({
        paused: true,
        onReverseComplete: () => {
            this.isAnimating = false;
        }
    });

    constructor() {

        if (this.sliders.length > 0) {

            this.setScale();

            for (let i: number = 0; i < this.sliders.length; i++) {
                let index: number = i + 1;
                this.init(index);
            }

            this.cursorPosition();

            this.tl
                .add('start')
                .to(this.cursorArrowLeft, 0.3, {
                    x: 30,
                    ease: Power4.easeInOut
                }, 'start')
                .to(this.cursorArrowRight, 0.3, {
                    x: 30,
                    delay: 0.1,
                    ease: Power4.easeInOut
                }, 'start')
                .add('middle', '-=0.2')
                .to(this.circle1path, 0.4, {
                    strokeDashoffset: 94,
                    ease: Power0.easeOut
                }, 'middle')
                .to(this.circle1, 0.5, {
                    rotation: '180deg',
                    ease: Power0.easeOut
                }, 'middle')
                .add('finish', '-=0.2')
                .to(this.circle2path, 0.6, {
                    strokeDashoffset: 0,
                    ease: Power0.easeOut,
                }, 'finish')
                .to(this.circle2, 0.7, {
                    rotation: '360deg',
                    ease: Power0.easeOut,
                    onComplete: () => {
                        this.isAnimating = false;
                    }
                }, 'finish');

            document.addEventListener('mousemove', (ev: any): void => {
                let x: number = ev.pageX - (this.pointer.clientWidth / 2);
                let y: number = ev.pageY - (Math.max(window.pageYOffset, this.docEl.scrollTop, document.body.scrollTop)) - (this.pointer.clientHeight / 2);

                TweenMax.to(this.pointer, 0.1, {
                    x: x,
                    y: y,
                    ease: Power4.easeOut
                });
            });
        }
    }

    private setScale(): void {
        let scale: number = 0.6;

        if (window.innerWidth < 801) {
            scale = 0.8
        }

        if (window.innerWidth < 481) {
            scale = 0.9
        }

        // @ts-ignore
        for (let img of this.images) {
            img.dataset.swiperParallaxScale = scale;
        }
    }

    private init(index: number): void {
        // @ts-ignore
        let swiper: any = new Swiper(`.swiper-container-${index}`, {
            // freeMode: true,
            // freeModeSticky: true,
            // freeModeMinimumVelocity: 0.7,
            // freeModeMomentumRatio: 0.4,
            parallax: true,
            watchSlidesProgress: true,
            longSwipesRatio: 0,
            pagination: {
                el: '.swiper-pagination',
                type: "custom",
                renderCustom: function (swiper: any, current, total): string {
                    let i = current ? current : 0;
                    return `${("0" + i).slice(-2)} / ${("0" + total).slice(-2)}`;
                }
            },
            on: {
                touchMove: (ev: any): void => {
                    let x: number = ev.pageX - (this.pointer.clientWidth / 2);
                    let y: number = ev.pageY - (Math.max(window.pageYOffset, this.docEl.scrollTop, document.body.scrollTop)) - (this.pointer.clientHeight / 2);

                    TweenMax.to(this.pointer, 0.1, {
                        x: x,
                        y: y,
                        ease: Power4.easeOut
                    });
                }
            }
        });
    }

    private cursorPosition() {

        TweenMax.set(this.pointer, {
            opacity: 0,
        });

        if (is.touchDevice()) {
            return;
        }

        for (let i = 0; i < this.containers.length; i++) {

            this.containers[i].addEventListener('mouseover', (): void => {
                TweenMax.to(this.pointer, 0.2, {
                    opacity: 1,
                    ease: Power4.easeOut
                });
            });

            this.containers[i].addEventListener('mouseout', (): void => {
                TweenMax.to(this.pointer, 0.2, {
                    opacity: 0,
                    ease: Power4.easeOut
                });
            });

            // @ts-ignore
            const galleryContainer: any = this.containers[i].querySelector('.js-kuwait-gallery-container');

            if (galleryContainer !== null && galleryContainer !== undefined) {
                galleryContainer.addEventListener('mouseenter', (): void => {
                    if (this.isAnimating) {
                        return;
                    }

                    this.isAnimating = true;

                    this.tl.timeScale(1).play();
                });

                galleryContainer.addEventListener('mouseleave', (): void => {
                    this.tl.timeScale(1.5).reverse();
                });
            }
        }
    }

}
