import Swiper from 'swiper';
import {TimelineMax, TweenMax, Power4, Expo, Power3} from 'gsap';
// @ts-ignore
import is from 'is_js';
import {HorizontalParallax} from "@/components/HorizontalParallax";

export class Menu {
    private readonly _body: HTMLElement = <HTMLElement>document.querySelector('body');

    private readonly _titles: NodeListOf<HTMLElement> = document.querySelectorAll('.js-menu-project-title-item');
    private readonly _element: HTMLElement = <HTMLElement>document.querySelector('.js-menu-swiper-container');
    private readonly pointer: HTMLElement = <HTMLElement>document.querySelector('.js-gallery-cursor');
    private readonly _docEl: HTMLElement = <HTMLElement>document.documentElement;
    private readonly _obj: NodeListOf<Element> = document.querySelectorAll('.js-element-node');
    private readonly _swiperContainer: string = '.js-menu-swiper-container';
    private readonly _swiperPagination: string = '.js-menu-slider-counter';
    private swiper: any;

    // menu open/close controller
    private readonly _hamburgerButton: HTMLElement = <HTMLElement>document.querySelector('.js-hamburger-button');
    private isMenuOpened: boolean = false;
    private readonly _menu: HTMLElement = <HTMLElement>document.querySelector('.js-kuwait-menu');
    private readonly _sliderItems: NodeListOf<Element> = document.querySelectorAll('.js-kuwait-menu-slider-item');
    private readonly _staggerItems: NodeListOf<Element> = document.querySelectorAll('.js-menu-stagger-item');
    private readonly _staggerTitles: NodeListOf<Element> = document.querySelectorAll('.js-menu-stagger-title');
    private currentSlideIndex: number = 0;
    private previousSlideIndex: any = null;
    private isClicked: boolean = false;

    // menu scroll to controller
    private readonly _links: NodeListOf<Element> = document.querySelectorAll('.js-scroll-to-link');
    private readonly _targets: NodeListOf<Element> = document.querySelectorAll('.js-scroll-to-target');
    private readonly _horizontalParallax: HorizontalParallax;


    //region getters
    get element(): HTMLElement {
        return this._element;
    }

    get obj(): NodeListOf<Element> {
        return this._obj;
    }

    get docEl(): HTMLElement {
        return this._docEl;
    }

    get sliderItems(): NodeListOf<Element> {
        return this._sliderItems;
    }

    get titles(): NodeListOf<Element> {
        return this._titles;
    }

    get staggerItems(): NodeListOf<Element> {
        return this._staggerItems;
    }

    get staggerTitles(): NodeListOf<Element> {
        return this._staggerTitles;
    }

    get swiperContainer(): string {
        return this._swiperContainer;
    }

    get swiperPagination(): string {
        return this._swiperPagination;
    }

    get hamburgerButton(): HTMLElement {
        return this._hamburgerButton;
    }

    get menu(): HTMLElement {
        return this._menu;
    }

    get links(): NodeListOf<Element> {
        return this._links;
    }

    get targets(): NodeListOf<Element> {
        return this._targets;
    }

    //endregion

    //region constructor
    constructor(horizontalParallax: HorizontalParallax) {
        this._horizontalParallax = horizontalParallax;
        if (this.element === null) {
            return;
        }

        if (is.safari()) {
            const html: HTMLElement = <HTMLElement>document.querySelector('html');
            const wrapper: HTMLElement = <HTMLElement>document.querySelector('#kuwait-main-wrapper');
            wrapper.style.height = 'auto';
            this._body.style.height = 'auto';
            html.style.height = 'auto';

        }

        this.init();
        this.menuSlider();
        this.scrollPositionController();

        // if (is.safari()) {
        setTimeout(() => {
            this.titleWidthSet();
        }, 1000);
        // }
    }

    //endregion

    //region methods
    private init(): void {
        // console.log('Menu init()');
    }

    private menuSlider(): void {
        // @ts-ignore
        this.swiper = new Swiper(this.swiperContainer, {
            mousewheel: true,
            longSwipesRatio: 0,
            pagination: {
                el: this.swiperPagination,
                type: "custom",
                renderCustom: function (swiper: any, current, total): string {
                    let i = current ? current : 0;
                    return `${("0" + i).slice(-2)} / ${("0" + total).slice(-2)}`;
                }
            },
            on: {
                touchMove: (ev: any): void => {
                    let x: number = ev.pageX - (this.pointer.clientWidth / 2);
                    let y: number = ev.pageY - this.docEl.scrollTop - (this.pointer.clientHeight / 2);

                    TweenMax.to(this.pointer, 0.1, {
                        x: x,
                        y: y,
                        ease: Power4.easeOut
                    });
                },
                slideChange: (): void => {
                    this.currentSlideIndex = this.swiper.activeIndex;
                    this.previousSlideIndex = this.swiper.previousIndex;

                    this.staggerItemsAnimation();
                },
            }
        });

        this.menuController();
    }

    private staggerItemsAnimation(): void {
        let currentItems: NodeListOf<Element> = this.sliderItems[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-item');
        let currentTitles: NodeListOf<Element> = this.titles[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-title');
        let allTitles: NodeListOf<Element> = document.querySelectorAll('.js-menu-stagger-title');
        let previousItems: NodeListOf<Element> = this.sliderItems[this.previousSlideIndex].querySelectorAll('.js-menu-stagger-item');

        this.titlesClassController(allTitles);
        this.titlesClassController(currentTitles, true);

        let showTl = new TimelineMax({
            onStart: () => {
                let allInactiveTitles: NodeListOf<Element> = document.querySelectorAll('.is-inactive-menu-title');
                this.staggerTitlesInitialPositioning(allInactiveTitles, true);
            },
            onComplete: () => {
                let allInactiveTitles: NodeListOf<Element> = document.querySelectorAll('.is-inactive-menu-title');
                this.staggerTitlesInitialPositioning(allInactiveTitles, true);
            }
        });

        showTl
            .staggerTo(currentItems, 0.5, {
                y: 0,
                autoAlpha: 1,
                ease: Power4.easeOut
            }, 0.15, 'staggerItems')
            .add('staggerTitles', '-=0.4')
            .staggerTo(currentTitles, 0.9, {
                cycle: {
                    x: (): string => {
                        let random = Math.random();
                        return `${random * 50}%`;
                    },
                    ease: Power3.easeOut
                },
            }, 0.15, 'staggerTitles');

        this.staggerItemsInitialPositioning(previousItems, true);
    }

    private titlesClassController(titles: NodeListOf<Element>, removeClass: boolean = false): void {

        if (removeClass) {
            for (let i: number = 0; i < titles.length; i++) {
                titles[i].classList.remove('is-inactive-menu-title');
            }
            return;
        }
        for (let i: number = 0; i < titles.length; i++) {
            titles[i].classList.add('is-inactive-menu-title');
        }
    }

    private menuController(): void {
        // set all stagger items to initial position
        this.staggerItemsInitialPositioning(this.staggerItems);

        // set all stagger titles to initial position
        this.staggerTitlesInitialPositioning(this.staggerTitles);

        let currentTitles: NodeListOf<Element> = this.titles[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-title');
        this.titlesClassController(currentTitles, true);

        this.hamburgerButton.addEventListener('click', (ev) => {
            if (this.isClicked) {
                ev.preventDefault();
                return;
            }

            this.isClicked = true;
            let currentItems: NodeListOf<Element> = this.sliderItems[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-item');
            let currentTitles: NodeListOf<Element> = this.titles[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-title');
            const button: HTMLElement = <HTMLElement>ev.currentTarget;

            if (button.classList.contains('is-opened') && this.isMenuOpened) {
                this.closeNav(button, currentItems, currentTitles);
            } else {
                this.openNav(button, currentItems, currentTitles);
            }
        });
    }

    private openNav(button: HTMLElement, currentItems: NodeListOf<Element>, currentTitles: NodeListOf<Element>): void {
        const mainWrapper: HTMLElement = <HTMLElement>document.querySelector('#kuwait-main-wrapper');
        button.classList.add('is-opened');
        this.isMenuOpened = true;

        let openTl = new TimelineMax({
            onStart: () => {
                this._body.style.overflow = "hidden";
            }
        });

        openTl
            .add('start')
            .to(mainWrapper, 0.7, {
                scale: 1.02,
                ease: Expo.easeOut,
                onComplete: () => {
                    this.isClicked = false;
                }
            }, 'start')
            .to(this.menu, 0.7, {
                // x: '0%',
                scale: 1,
                autoAlpha: 1,
                ease: Expo.easeOut
            }, 'start')
            .add('staggerItems', '-=0.3')
            .staggerTo(currentItems, 0.3, {
                y: 0,
                autoAlpha: 1,
                ease: Power4.easeOut
            }, 0.15, 'staggerItems')
            .add('staggerTitles', '-=0.4')
            .staggerTo(currentTitles, 0.9, {
                cycle: {
                    x: (): string => {
                        let random = Math.random();
                        return `${random * 50}%`;
                    },
                    ease: Power3.easeOut
                },
            }, 0.15, 'staggerTitles');
    }

    private closeNav(button: HTMLElement, currentItems: NodeListOf<Element>, currentTitles: NodeListOf<Element>): void {
        const mainWrapper: HTMLElement = <HTMLElement>document.querySelector('#kuwait-main-wrapper');
        button.classList.remove('is-opened');
        this.isMenuOpened = false;
        let allTitles: NodeListOf<Element> = document.querySelectorAll('.js-menu-stagger-title');

        let closeTl = new TimelineMax({
            onStart: () => {
                this.swiper.allowClick = false;
                this.swiper.allowSlideNext = false;
                this.swiper.allowSlidePrev = false;
                this.swiper.allowTouchMove = false;
                this.menu.style.pointerEvents = "none";
            },
            onUpdate: () => {
                if (this._body.style.overflow !== "") {
                    this._body.style.overflow = "";
                }
            },
            onComplete: () => {
                this.isClicked = false;
                this.staggerTitlesInitialPositioning(allTitles);
                this.swiper.allowClick = true;
                this.swiper.allowSlideNext = true;
                this.swiper.allowSlidePrev = true;
                this.swiper.allowTouchMove = true;
                this.menu.style.pointerEvents = "";
            }
        });
        closeTl
            .add('staggerTitles')
            .staggerTo(currentItems, 0.3, {
                y: -30,
                autoAlpha: 0,
                ease: Power4.easeOut
            }, 0.1)
            .staggerTo(currentTitles, 0.9, {
                cycle: {
                    x: (i: number): string => {
                        return (i + 1) % 2 === 0 ? '-100%' : '100%'
                    },
                    ease: Power3.easeOut
                }
            }, 0, 'staggerTitles')
            .add('menu', '-=0.3')
            .to(mainWrapper, 0.7, {
                scale: 1,
                ease: Expo.easeOut
            }, 'menu')
            .to(this.menu, 0.7, {
                // x: '0%',
                scale: 1.02,
                autoAlpha: 0,
                ease: Expo.easeOut
            }, 'menu');
    }

    private staggerTitlesInitialPositioning(elementsArray: NodeListOf<Element>, animate: boolean = false): void {
        let offset: string = '100%';
        for (let titleIndex: number = 0; titleIndex < elementsArray.length; titleIndex++) {
            if ((titleIndex + 1) % 2 === 0) {
                offset = '-100%';
            } else {
                offset = '100%';
            }

            if (animate) {
                TweenMax.to(elementsArray[titleIndex], 0.3, {
                    x: offset,
                    ease: Power3.easeOut
                });
            } else {
                TweenMax.set(elementsArray[titleIndex], {
                    x: offset
                });
            }
        }
    }

    private staggerItemsInitialPositioning(elementsArray: NodeListOf<Element>, animate: boolean = false): void {
        if (animate) {
            TweenMax.to(elementsArray, 0.3, {
                y: -30,
                autoAlpha: 0,
                ease: Power3.easeOut
            });
        } else {
            TweenMax.set(elementsArray, {
                y: -30,
                autoAlpha: 0
            });
        }
    }

    private setScroll(scrollToIndex: number): void {
        // @ts-ignore
        let mainWrapper: HTMLElement = <HTMLElement>document.querySelector('#kuwait-main-wrapper').querySelector('.scroll-content');
        let currentTarget: any = null;
        let currentTargetIndex: number = 0;
        let elem: Object = {};
        let top: number = 0;
        let currentItems: NodeListOf<Element> = this.sliderItems[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-item');
        let currentTitles: NodeListOf<Element> = this.titles[this.currentSlideIndex].querySelectorAll('.js-menu-stagger-title');

        if (is.safari()) {
            // @ts-ignore
            mainWrapper = <HTMLElement>document.querySelector('#kuwait-main-wrapper').querySelector('.c-kuwait-main-wrapper__inner');
        }

        for (let i: number = 0; i < this.targets.length; i++) {
            currentTarget = this.targets[i];
            currentTargetIndex = parseInt(currentTarget.dataset.targetIndex);

            if (currentTargetIndex === scrollToIndex) {
                elem = currentTarget.getBoundingClientRect();
                // @ts-ignore
                // top = -mainWrapper.getBoundingClientRect().top + (currentTarget.offsetTop - currentTarget.scrollTop + currentTarget.clientTop);
                top = currentTarget.offsetTop;

                if (is.not.safari()) {
                    TweenMax.to(this._horizontalParallax.scroll.offset, 0.6, {
                        y: top,
                        onUpdate: () => {
                            this._horizontalParallax.scroll.update();
                        }
                    });

                    TweenMax.set(mainWrapper, {
                        y: -top
                    });
                } else {
                    window.scrollTo(0, top);
                }

                this.closeNav(this.hamburgerButton, currentItems, currentTitles);
                return;
            }
        }
    }

    private scrollPositionController(): void {
        let targetIndex: number = 0;
        for (let i: number = 0; i < this.links.length; i++) {
            const currentLink: HTMLElement = <HTMLElement>this.links[i];
            currentLink.addEventListener('click', (ev) => {
                ev.preventDefault();
                if (currentLink.dataset.scrollToIndex !== undefined) {
                    targetIndex = parseInt(currentLink.dataset.scrollToIndex);
                    this.setScroll(targetIndex);
                }
            });
        }
    }

    private titleWidthSet(): void {
        for (let i: number = 0; i < this.staggerTitles.length; i++) {
            const currentTitleSpan: HTMLElement = <HTMLElement>this.staggerTitles[i].querySelector('span');
            const currentSpanWidth: number = currentTitleSpan.clientWidth;
            let currentTitle: HTMLElement = <HTMLElement>this.staggerTitles[i];
            currentTitle.style.width = `${currentSpanWidth}px`;
        }
    }

    //endregion
}
