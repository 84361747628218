import {TweenMax, Power0} from 'gsap';
// @ts-ignore
import ScrollMagic from 'scrollmagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import {HorizontalParallax} from "@/components/HorizontalParallax";
// @ts-ignore
import is from 'is_js';

export class ItemParallax {
    private readonly items = document.querySelectorAll<HTMLElement>('.js-parallax-item');
    private readonly controller = new ScrollMagic.Controller({
        refreshInterval: 0
    });
    private scenes: any = [];
    private readonly _horizontalParallax: HorizontalParallax;

    constructor(horizontalParallax: HorizontalParallax) {
        this._horizontalParallax = horizontalParallax;

        if (this.items.length > 0) {
            for (let i: number = 0; i < this.items.length; i++) {
                this.init(i);
            }

            let y = 0;

            this.controller.scrollPos(function () {
                return y;
            });

            if (is.not.safari()) {
                this._horizontalParallax.scroll.addListener((status) => {
                    y = status.offset.y;

                    if (is.chrome()){
                    this.controller.update();
                    } else {
                        this.scenes.forEach(function (scene: any) {
                            scene.refresh();
                        });
                    }
                });
            }
        }
    }

    private init(index: number): void {
        let item: HTMLElement = <HTMLElement>this.items[index];

        const duration = item.offsetHeight + window.innerHeight;
        let offset: number = 1;
        if (item.dataset.offset) {
            offset = parseInt(item.dataset.offset) + 1;
        }

        let tween = TweenMax.fromTo(item, 0.1, {
            y: 10 * offset
        }, {
            y: -170 * offset,
            ease: Power0.easeNone,
        });

        this.scenes[index] = new ScrollMagic.Scene({
            triggerElement: item,
            duration: duration,
            triggerHook: 1,
            offset: 0
        })
            .setTween(tween)
            .addTo(this.controller);
    }
}
